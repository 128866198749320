<template>
    <div class="mainbox">
        <div class="detail_right">
            <div class="detail_right_til">
                <!-- <img src="~@/assets/img/TechnicalCollege/12.png" alt=""> -->
                常见问题
            </div>
            <div class="question_list">
                <div v-for="(item, index) in qusetionList" :key="index" class="question_item">
                    <div class="q_ask">
                        <div>Q</div>
                        <div>{{ item.questionName }}</div>
                    </div>
                    <div class="q_answer">
                        <div>A</div>
                        <div class="twoEllipsis"> {{ item.questionAnswer }} </div>
                    </div>
                </div>
                <el-empty v-if="!qusetionList || qusetionList.length == 0" description="暂无数据"></el-empty>

            </div>
            <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                class="pagination partner-pagination" :current-page="search.pageNum" :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
                @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]" />
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            id: '',
            total: 0,
            lists: [],
            search: {
                pageSize: 10,
                pageNum: 1,
                projectTypeId: '',
                schoolId: this.id,
                projectCategoryId: ''
            },
            qusetionList: []
        };
    },
    created() {

        this.search.schoolId = this.$route.query.id;
        this.projectType = localStorage.getItem('projectType');
        this.search.projectTypeId = localStorage.getItem('projectTypeId');
        this.getSchoolProblemPcList()
    },
    mounted() {

    },
    methods: {
        getSchoolProblemPcList() {
            know.getSchoolProblemPcList({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
                this.total = res.total
            });
        },
        handleSizeChange(val) {
            this.search.pageNum = 1
            this.search.pageSize = val
            this.getSchoolProblemPcList()
        },
        handleCurrentChange(val) {
            this.search.pageNum = val
            this.getSchoolProblemPcList()
        },
    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;

    .detail_right {
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        padding-bottom: 20px;

        .detail_right_til {
            height: 70px;
            text-align: center;
            line-height: 70px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 26px;
            color: #444444;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
        }

        .question_list {
            padding: 30px;
            padding-top: 0;

            .question_item {
                margin-top: 25px;

                .q_ask {
                    display: flex;
                    align-items: baseline;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #FECD61;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;

                    }

                    >div:nth-child(2) {
                        padding: 5px 20px;
                        background: #FECD61;
                        border-radius: 15px 15px 15px 15px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        margin-left: 10px;
                    }
                }

                .q_answer {
                    display: flex;
                    align-items: baseline;
                    margin-top: 12px;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #E3E3E3;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;
                    }

                    >div:nth-child(2) {
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #555555;
                        margin-left: 10px;
                    }
                }
            }
        }
    }




}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>